import styles from "../../scss/pageComponent/checkoutCart.module.scss";
import { InputField } from "../../common/theme/Input";
import { useFormik } from "formik";
import { couponCodeFormSchema } from "../../validations/CheckoutSchema";
import React, { useEffect, useState } from "react";
import useFetch from "../../customHook/useFetch";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setCheckoutOrderSummarySlice } from "../../redux/CheckoutForm/CheckoutFormSlice";
import { RotatingLines } from "react-loader-spinner";
import { Grid } from "@mui/material";
import ComplexGrid from "./CheckoutBundelInfo";
import AppButton from "../../common/theme/Button";
import { Decrypt } from "../../common/utils/Functions";
interface IProps {
  idOfStore?: any;
  orgId?: any;
  summaryData?: any;
  data?: any;
  selected?: any;
  grandTotal: number;
  grandTotalCopy: number;
  setGrandTotal: any;
  setGrandTotalCopy: any;
}
// let TotalAmount: any = "500";
// let subTotal: any = TotalAmount;

const DiscountAmount: any = {
  name: "Discount",
  price: "0.00",
};

const CheckoutOrderSummary = ({
  orgId,
  idOfStore,
  summaryData,
  data,
  selected,
  grandTotal,
  grandTotalCopy,
  setGrandTotal,
  setGrandTotalCopy,
}: IProps) => {
  const storeData = useSelector((state: any) => state.storeDataSlice.data);
  const customerInformation = useSelector(
    (state: any) => state.storeCheckoutForm.FirstFormSlice
  );
  const shippingMethod = useSelector(
    (state: any) => state.storeCheckoutForm.SecondFormSlice
  );
  const payment = useSelector(
    (state: any) => state.storeCheckoutForm.ThirdFormSlice
  );
  const [reLoadCart, setReLoadCart] = useState(false);
  const [discount, setDiscount] = useState(DiscountAmount);
  const [couponId, setCouponId] = useState(0);
  const [showDiscountTab, setShowDiscountTab] = useState(false);
  const [dataOfPrice, setdataOfPrice] = useState([
    {
      name: "Sales Tax",
      price: "0.00",
    },
    {
      name: "Shipping Fee",
      price: "0.00",
    },
    {
      name: "Processing Fee",
      price: "0.00",
    },
  ]);

  const [couponCode, setCouponCode] = useState("");
  const [showProcessingFee, setShowProcessingFee] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const [subTotal, setsubTotal] = useState(0);
  const [totalFee, setTotalFee] = useState(0);
  const cartDataInfoD = localStorage?.getItem("cart");
  const cartDataInfo = Decrypt(cartDataInfoD);
  const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
  const [bundleData, setBundleData] = useState<any>([]);
  const [showShipping, setShowShipping] = useState(true);
  const [loaderApplyCoupon, setLoaderApplyCoupon] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const isShipping = data.find((val: any) => val.value === 1);

    if (isShipping && storeData?.shipping_method_type !== 0) {
      setShowShipping(true);
    } else {
      setShowShipping(false);
    }
  }, [data]);

  useEffect(() => {
    const cartDataInfoD = localStorage?.getItem("cart");
    const cartDataInfo = Decrypt(cartDataInfoD);
    const cartData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
    setBundleData(cartData);
  }, [reLoadCart]);

  useEffect(() => {
    if (bundleData) {
      let totalPrice = 0;
      let totalItem = 0;
      bundleData.map((cartItem: any) => {
        if (cartItem.isSingleProduct) {
          totalPrice += cartItem.club_price * cartItem.quantity;
          totalItem += cartItem.quantity;
        } else {
          if (storeData?.payment_handle_type === 1) {
            cartItem.bundle_products &&
              cartItem.bundle_products.map((product: any) => {
                totalItem += product.store_product_detail.quantity;
                totalPrice +=
                  product.store_product_detail.club_price *
                  product.store_product_detail.quantity;
              });
          }
        }
      });
      setsubTotal(totalPrice);
      setTotalAmount(totalPrice);
    }
  }, [bundleData, couponCode]);

  useEffect(() => {
    const sale_tax = dataOfPrice[0].price;
    const processing_fee = dataOfPrice[2].price;
    const ship_to_home = dataOfPrice[1].price;
    let used_coupon_amount = 0;
    if (couponId) {
      if (selectedCoupon?.type === 1 || selectedCoupon?.type === 2) {
        used_coupon_amount = Number(totalAmount) - Number(discount?.price) > 0 ? Number(discount?.price) : totalAmount;
      } else if (selectedCoupon?.type === 3 || selectedCoupon?.type === 4) {
        used_coupon_amount = (Number(totalAmount) + Number(totalFee)) - Number(discount?.price) > 0 ? Number(discount?.price) : (Number(totalAmount) + Number(totalFee));
      }
    } else {
      used_coupon_amount = 0;
    }

    const orderSummary = {
      org_id: orgId,
      store_id: idOfStore,
      coupon_id: couponId,
      coupon_code: couponCode,
      coupon_amount: discount?.price
        ? parseFloat(discount.price.toString()).toFixed(2)
        : 0,
      total_amount: parseFloat(grandTotal.toString()).toFixed(2),
      company_bear_amount: couponId ? Number(grandTotalCopy) < 0.5 ? parseFloat(grandTotalCopy.toString()).toFixed(2) : 0 : 0,
      used_coupon_amount: used_coupon_amount,
      sub_total: parseFloat(subTotal.toString()).toFixed(2),
      sale_tax: parseFloat(sale_tax.toString()).toFixed(2),
      processing_fee: parseFloat(processing_fee.toString()).toFixed(2),
      ship_to_home: parseFloat(ship_to_home.toString()).toFixed(2),
      couponDetail: selectedCoupon,
    };
    dispatch(setCheckoutOrderSummarySlice(orderSummary));
  }, [subTotal, discount, totalFee, couponCode, grandTotal, grandTotalCopy, selectedCoupon, totalAmount]);

  useEffect(() => {
    const dataOfPriceCopy: any = [...dataOfPrice];
    if (summaryData.tax_type === 1) {
      dataOfPriceCopy[0].price = `${(summaryData.tax * subTotal) / 100}`;
    } else if (summaryData.tax_type === 2) {
      dataOfPriceCopy[0].price = (summaryData.tax * subTotal) / 100;
    }

    // condition for Processing Tax or Processing Fee
    if (summaryData.shipping_fee_type === 1) {
      dataOfPriceCopy[1].price = `${summaryData.shipping_amount}`;
    } else if (summaryData.shipping_fee_type === 2) {
      dataOfPriceCopy[1].price = (summaryData.shipping_amount * subTotal) / 100;
    }

    // condition for Shipping fee calculation
    const isSingleProduct = bundleData?.find(
      (item: any) => item?.isSingleProduct
    );

    if (
      summaryData?.is_charge_processing_fee_type === 1 &&
      (summaryData?.payment_handle_type === 1 ||
        (summaryData?.payment_handle_type === 3 &&
          isSingleProduct !== undefined))
    ) {
      setShowProcessingFee(true);

      dataOfPriceCopy[2].price =
        subTotal !== 0 ? (subTotal * 2.9) / 100 + (0.3 * 100) / 100 : 0;
    } else if (
      summaryData.is_charge_processing_fee_type === 2 ||
      summaryData.is_charge_processing_fee_type === 0
    ) {
      setShowProcessingFee(false);
      dataOfPriceCopy[2].price = "0.00";
    }

    if (summaryData.store_fees) {
      summaryData?.store_fees?.map((val: any) => {
        let datatoBeAdded: any = {};

        const isExistIndex = dataOfPriceCopy.findIndex(
          (item: any) => item.fee_id === val.fee_id
        );
        if (val.fee_detail.type == 2) {
          // Here it means flat price
          datatoBeAdded = {
            fee_id: val.fee_id,
            name: val.fee_detail.name,
            price: val.fee_detail.amount,
          };
        } else {
          datatoBeAdded = {
            fee_id: val.fee_id,
            name: val.fee_detail.name,
            price: (Number(val.fee_detail.amount) * Number(subTotal)) / 100,
          };
        }

        if (isExistIndex === -1) {
          dataOfPriceCopy.push(datatoBeAdded);
        } else {
          dataOfPriceCopy[isExistIndex] = datatoBeAdded;
        }
        return dataOfPriceCopy;
      });
      setdataOfPrice([...dataOfPriceCopy]);

      // dataOfPrice.
    }
  }, [summaryData, discount, selectedCoupon, subTotal, summaryData.tax]);

  useEffect(() => {
    const newTotal = dataOfPrice.reduce((inti: any, curr: any) => {
      return (
        Number(parseFloat(curr.price).toFixed(2)) +
        Number(parseFloat(inti).toFixed(2))
      );
    }, 0);
    setTotalFee(newTotal);
  }, [dataOfPrice]);

  let initialValuesData: any = {
    couponCode: "",
  };

  const [CouponApi, { response: getResponse, error: couponError }] = useFetch(
    `/public-coupon/${idOfStore}/${couponCode}`,
    {
      method: "get",
    }
  );
  useEffect(() => {
    if (couponCode) {
      CouponApi();
    }
  }, [couponCode]);

  useEffect(() => {
    let subTotalCopy = totalAmount;
    if (selectedCoupon?.type === 1 || selectedCoupon?.type === 2) {
      if (couponCode !== "") {
        subTotalCopy =
          Number(subTotalCopy) - Number(DiscountAmount.price) > 0
            ? Number(subTotalCopy) - Number(DiscountAmount.price)
            : 0;
      } else {
        subTotalCopy = totalAmount;
      }
    } else {
      subTotalCopy = totalAmount;
    }
    setsubTotal(subTotalCopy);
    const total = Number(totalFee) + Number(subTotalCopy);
    if (selectedCoupon?.type === 3 || selectedCoupon?.type === 4) {
      if (couponCode !== "") {
        setGrandTotal(
          Number(total) - Number(DiscountAmount.price) > 0.5
            ? Number(total) - Number(DiscountAmount.price)
            : 0
        );
        setGrandTotalCopy(Number(total) - Number(DiscountAmount.price) > 0
          ? Number(total) - Number(DiscountAmount.price)
          : 0)
      } else {
        setGrandTotal(total);
        setGrandTotalCopy(total);
      }
    } else {
      setGrandTotal(total);
      setGrandTotalCopy(total);
    }
    const dataOfPriceCopy: any = [...dataOfPrice];
    if (summaryData.tax_type === 1 || summaryData.tax_type === 2) {
      dataOfPriceCopy[0].price = (summaryData.tax * subTotal) / 100;
    }

    setdataOfPrice([...dataOfPriceCopy]);
  }, [totalFee, subTotal, couponCode, selectedCoupon]);

  useEffect(() => {
    if (getResponse && getResponse?.status === 1) {
      toast.success("Coupon applied successfully");
      setShowDiscountTab(true);
      setSelectedCoupon(getResponse.data);
      setCouponId(getResponse.data.id);

      if (getResponse?.data?.type === 1) {
        DiscountAmount.price = `${getResponse?.data.amount}`;
        // setsubTotal(
        //   Number(subTotal) - Number(DiscountAmount.price) > 0
        //     ? Number(subTotal) - Number(DiscountAmount.price)
        //     : 0
        // );
      } else if (getResponse?.data?.type === 2) {
        DiscountAmount.price = (getResponse?.data.amount * subTotal) / 100;
        // setsubTotal(
        //   Number(subTotal) - Number(DiscountAmount.price) > 0
        //     ? Number(subTotal) - Number(DiscountAmount.price)
        //     : 0
        // );
      } else if (getResponse?.data?.type === 3) {
        DiscountAmount.price = `${getResponse?.data.amount}`;
        // setGrandTotal(
        //   Number(grandTotal) - Number(DiscountAmount.price) > 0
        //     ? Number(grandTotal) - Number(DiscountAmount.price)
        //     : 0
        // );
      } else if (getResponse?.data?.type === 4) {
        DiscountAmount.price =
          (Number(getResponse?.data.amount) * Number(grandTotal)) / 100;
        // setGrandTotal(
        //   Number(grandTotal) - Number(DiscountAmount.price) > 0
        //     ? Number(grandTotal) - Number(DiscountAmount.price)
        //     : 0
        // );
      }
      setLoaderApplyCoupon(false);
      setDiscount(DiscountAmount);
    } else if (getResponse && getResponse?.status !== 1) {
      // toast.error(getResponse?.message);
      setLoaderApplyCoupon(false);
    } else {
      setDiscount(subTotal);
      setShowDiscountTab(false);
      setLoaderApplyCoupon(false);
    }
  }, [getResponse]);

  useEffect(() => {
    if (couponError) {
      // toast.error(couponError?.message);
      formik.setFieldError("couponCode", couponError?.message);
      setLoaderApplyCoupon(false);
    }
  }, [couponError]);

  const formik = useFormik({
    initialValues: initialValuesData,
    validationSchema: couponCodeFormSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setCouponCode("");
      setLoaderApplyCoupon(true);
      setTimeout(() => {
        const valCopy = values.couponCode;
        setCouponCode(valCopy);
      }, 1000);
    },
  });
  const handleRemoveCouponCode = () => {
    setDiscount({
      name: "Discount",
      price: "0.00",
    });
    setShowDiscountTab(false);
    setCouponCode("");
    setCouponId(0);
    setSelectedCoupon(null);
    formik.resetForm();
  };

  return (
    <>
      <Grid className={styles.CheckoutCartOrderSummary}>
        <p className={styles.CheckoutCartOrderSummaryHeading}>Order Summary</p>
        {bundleData.map((val: any, index: number) => {
          return (
            <ComplexGrid
              key={index}
              cartItem={val}
              reLoadCart={reLoadCart}
              setReLoadCart={setReLoadCart}
            />
          );
        })}
        {showDiscountTab === true && selectedCoupon ? (
          <div className={styles.appliedCoupon}>
            <div className={styles.couponAppliedWrapper}>
              <div className={styles.couponCode}>
                <b>{selectedCoupon.coupon_code}</b> applied
              </div>
              <div className={styles.discountPrice}>
                {selectedCoupon.type === 1
                  ? `FLAT $${selectedCoupon.amount} from subtotal`
                  : selectedCoupon.type === 2
                    ? `${selectedCoupon.amount}% from the subtotal`
                    : selectedCoupon.type === 3
                      ? `FLAT $${selectedCoupon.amount} from grand total`
                      : `${selectedCoupon.amount}% from the grand total`}
              </div>
            </div>
            <div className={styles.removeCoupon}>
              <AppButton
                onClick={() => {
                  handleRemoveCouponCode();
                }}
              >
                Remove
              </AppButton>
            </div>
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <Grid container className={styles.couponWrapper}>
              <Grid item lg={8} xs={7}>
                <InputField
                  formik={formik}
                  label="Coupon or Gift Card"
                  placeholder="Type code here"
                  name="couponCode"
                />
              </Grid>
              <Grid className={styles.couponBtn} item lg={3} xs={4}>
                <AppButton
                  disabled={
                    formik.values.couponCode === "" ||
                    !formik.values.couponCode.replace(/\s/g, "").length ||
                    loaderApplyCoupon
                  }
                  type="submit"
                >
                  Apply{" "}
                  {loaderApplyCoupon ? (
                    <span
                      className="loadingBars"
                      style={{ display: "flex", marginRight: "5px" }}
                    >
                      <RotatingLines
                        strokeColor="currentColor"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </AppButton>
              </Grid>
            </Grid>
          </form>
        )}

        <div className={styles.priceWrapper}>
          <div className={styles.singlePrice}>
            <div className={styles.left}>
              <b>Subtotal</b>
            </div>

            <div className={styles.right}>
              <b>{`$${parseFloat(totalAmount.toString()).toFixed(2)}`}</b>
            </div>
          </div>

          {/* <div className={styles.singlePrice}>
            <div className={styles.left}>
              <b>Subtotal After Discount</b>
            </div>

            <div className={styles.right}>
              <b>{`$${parseFloat(subTotal.toString()).toFixed(2)}`}</b>
            </div>
          </div> */}

          {(selectedCoupon?.type === 1 || selectedCoupon?.type === 2) &&
            showDiscountTab == true ? (
            <div className={styles.singlePrice}>
              <div className={styles.left}>
                <b>{discount.name}</b>
              </div>

              <div className={styles.right}>
                <b>
                  -{" "}
                  {discount?.price
                    ? `$${parseFloat(discount.price.toString()).toFixed(2)}`
                    : ""}
                </b>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <h1>{dataOfPrice.length}</h1> */}
          {dataOfPrice.map((val: any, index: any) => {
            // if(data.value === 1 && val.name ==="Shipping Fee" )
            if (
              (val.name === "Shipping Fee" && !showShipping) ||
              (!showProcessingFee && val.name === "Processing Fee")
            ) {
            } else {
              return (
                <div className={styles.singlePrice}>
                  <div className={styles.left}>{val.name}</div>
                  <div className={styles.right}>{`$${parseFloat(
                    val.price.toString()
                  ).toFixed(2)}`}</div>
                </div>
              );
            }
          })}
          {(selectedCoupon?.type === 3 || selectedCoupon?.type === 4) &&
            showDiscountTab == true ? (
            <div className={styles.singlePrice}>
              <div className={styles.left}>
                <b>{discount.name}</b>
              </div>

              <div className={styles.right}>
                <b>
                  -{" "}
                  {discount?.price
                    ? `$${parseFloat(discount.price.toString()).toFixed(2)}`
                    : ""}
                </b>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className={styles.singlePrice}>
            <div className={styles.left}>
              <b>Grand Total</b>
            </div>

            <div className={styles.right}>
              <b>{showDiscountTab == true && Number(grandTotal) < 0.5 ? "$0.00" : `$${parseFloat(grandTotal.toString()).toFixed(2)}`}</b>
            </div>
          </div>
        </div>
        {/* {showPlaceOrder && (
          <div className={styles.orderButton}>
            <AppButton onClick={placeOrder} variant="outlined">
              Place Order
            </AppButton>
          </div>
        )} */}
      </Grid>
    </>
  );
};

export default CheckoutOrderSummary;
